var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2", offset: "2" } },
            [
              _c("p", { staticClass: "fw-700 fs-14" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(56, "Gate keeper")) +
                    "\n\t\t\t"
                ),
              ]),
            ]
          ),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.FormMSG(61, "Production accountant")) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(58, "UPM")) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.FormMSG(59, "Line producer")) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(60, "Studio")) + "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(62, "Step 1")) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled ||
                    (_vm.disableOtherStep1 && !_vm.step1.gateKeeper),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 1, "gateKeeper")
                  },
                },
                model: {
                  value: _vm.step1.gateKeeper,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1, "gateKeeper", $$v)
                  },
                  expression: "step1.gateKeeper",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled ||
                    (_vm.disableOtherStep1 && !_vm.step1.productionAccountant),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep(
                      $event,
                      1,
                      "productionAccountant"
                    )
                  },
                },
                model: {
                  value: _vm.step1.productionAccountant,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1, "productionAccountant", $$v)
                  },
                  expression: "step1.productionAccountant",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled || (_vm.disableOtherStep1 && !_vm.step1.upm),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 1, "upm")
                  },
                },
                model: {
                  value: _vm.step1.upm,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1, "upm", $$v)
                  },
                  expression: "step1.upm",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled ||
                    (_vm.disableOtherStep1 && !_vm.step1.lineProducer),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 1, "lineProducer")
                  },
                },
                model: {
                  value: _vm.step1.lineProducer,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1, "lineProducer", $$v)
                  },
                  expression: "step1.lineProducer",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled ||
                    (_vm.disableOtherStep1 && !_vm.step1.studio),
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 1, "studio")
                  },
                },
                model: {
                  value: _vm.step1.studio,
                  callback: function ($$v) {
                    _vm.$set(_vm.step1, "studio", $$v)
                  },
                  expression: "step1.studio",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(63, "Step 2")) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep2 ||
                    (_vm.disableOtherStep2 && !_vm.step2.gateKeeper) ||
                    _vm.step1.gateKeeper,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 2, "gateKeeper")
                  },
                },
                model: {
                  value: _vm.step2.gateKeeper,
                  callback: function ($$v) {
                    _vm.$set(_vm.step2, "gateKeeper", $$v)
                  },
                  expression: "step2.gateKeeper",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep2 ||
                    (_vm.disableOtherStep2 &&
                      !_vm.step2.productionAccountant) ||
                    _vm.step1.productionAccountant,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep(
                      $event,
                      2,
                      "productionAccountant"
                    )
                  },
                },
                model: {
                  value: _vm.step2.productionAccountant,
                  callback: function ($$v) {
                    _vm.$set(_vm.step2, "productionAccountant", $$v)
                  },
                  expression: "step2.productionAccountant",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep2 ||
                    (_vm.disableOtherStep2 && !_vm.step2.upm) ||
                    _vm.step1.upm,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 2, "upm")
                  },
                },
                model: {
                  value: _vm.step2.upm,
                  callback: function ($$v) {
                    _vm.$set(_vm.step2, "upm", $$v)
                  },
                  expression: "step2.upm",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep2 ||
                    (_vm.disableOtherStep2 && !_vm.step2.lineProducer) ||
                    _vm.step1.lineProducer,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 2, "lineProducer")
                  },
                },
                model: {
                  value: _vm.step2.lineProducer,
                  callback: function ($$v) {
                    _vm.$set(_vm.step2, "lineProducer", $$v)
                  },
                  expression: "step2.lineProducer",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep2 ||
                    (_vm.disableOtherStep2 && !_vm.step2.studio) ||
                    _vm.step1.studio,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 2, "studio")
                  },
                },
                model: {
                  value: _vm.step2.studio,
                  callback: function ($$v) {
                    _vm.$set(_vm.step2, "studio", $$v)
                  },
                  expression: "step2.studio",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-14" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(64, "Step 3")) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep3 ||
                    (_vm.disableOtherStep3 && !_vm.step3.gateKeeper) ||
                    _vm.step1.gateKeeper ||
                    _vm.step2.gateKeeper,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 3, "gateKeeper")
                  },
                },
                model: {
                  value: _vm.step3.gateKeeper,
                  callback: function ($$v) {
                    _vm.$set(_vm.step3, "gateKeeper", $$v)
                  },
                  expression: "step3.gateKeeper",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep3 ||
                    (_vm.disableOtherStep3 &&
                      !_vm.step3.productionAccountant) ||
                    _vm.step1.productionAccountant ||
                    _vm.step2.productionAccountant,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep(
                      $event,
                      3,
                      "productionAccountant"
                    )
                  },
                },
                model: {
                  value: _vm.step3.productionAccountant,
                  callback: function ($$v) {
                    _vm.$set(_vm.step3, "productionAccountant", $$v)
                  },
                  expression: "step3.productionAccountant",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep3 ||
                    (_vm.disableOtherStep3 && !_vm.step3.upm) ||
                    _vm.step1.upm ||
                    _vm.step2.upm,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 3, "upm")
                  },
                },
                model: {
                  value: _vm.step3.upm,
                  callback: function ($$v) {
                    _vm.$set(_vm.step3, "upm", $$v)
                  },
                  expression: "step3.upm",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep3 ||
                    (_vm.disableOtherStep3 && !_vm.step3.lineProducer) ||
                    _vm.step1.lineProducer ||
                    _vm.step2.lineProducer,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 3, "lineProducer")
                  },
                },
                model: {
                  value: _vm.step3.lineProducer,
                  callback: function ($$v) {
                    _vm.$set(_vm.step3, "lineProducer", $$v)
                  },
                  expression: "step3.lineProducer",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.disabledStep3 ||
                    (_vm.disableOtherStep3 && !_vm.step3.studio) ||
                    _vm.step1.studio ||
                    _vm.step2.studio,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckRoleByStep($event, 3, "studio")
                  },
                },
                model: {
                  value: _vm.step3.studio,
                  callback: function ($$v) {
                    _vm.$set(_vm.step3, "studio", $$v)
                  },
                  expression: "step3.studio",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "2" } }, [
            _c("p", { staticClass: "fw-700 fs-16" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(45, "Signature")) + "\n\t\t\t"
              ),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled: _vm.isDisabled || _vm.disabledToSignGateKeeper,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckToSign($event, "gateKeeper")
                  },
                },
                model: {
                  value: _vm.toSignGateKeeper,
                  callback: function ($$v) {
                    _vm.toSignGateKeeper = $$v
                  },
                  expression: "toSignGateKeeper",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled:
                    _vm.isDisabled || _vm.disabledToSignProductionAccountant,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckToSign($event, "productionAccountant")
                  },
                },
                model: {
                  value: _vm.toSignProdAcc,
                  callback: function ($$v) {
                    _vm.toSignProdAcc = $$v
                  },
                  expression: "toSignProdAcc",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled: _vm.isDisabled || _vm.disabledToSignUPM,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckToSign($event, "upm")
                  },
                },
                model: {
                  value: _vm.toSignUPM,
                  callback: function ($$v) {
                    _vm.toSignUPM = $$v
                  },
                  expression: "toSignUPM",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled: _vm.isDisabled || _vm.disabledToSignLineProducer,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckToSign($event, "lineProducer")
                  },
                },
                model: {
                  value: _vm.toSignLineProd,
                  callback: function ($$v) {
                    _vm.toSignLineProd = $$v
                  },
                  expression: "toSignLineProd",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _c("b-form-checkbox", {
                attrs: {
                  value: true,
                  "unchecked-value": false,
                  size: "lg",
                  disabled: _vm.isDisabled || _vm.disabledToSignStudio,
                },
                on: {
                  change: function ($event) {
                    return _vm.handleCheckToSign($event, "studio")
                  },
                },
                model: {
                  value: _vm.toSignStudio,
                  callback: function ($$v) {
                    _vm.toSignStudio = $$v
                  },
                  expression: "toSignStudio",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2", offset: "2" } },
            [
              _vm.toSignGateKeeper
                ? _c("v-select", {
                    attrs: {
                      disabled: _vm.isDisabled,
                      options: _vm.gateKeeperUserList,
                      label: "fullName",
                      reduce: (option) => option.id,
                      placeholder: "Select a user",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleChange(
                          $event,
                          _vm.DOCUMENT_VALIDATION_LEVEL.gateKeeper
                        )
                      },
                    },
                    model: {
                      value: _vm.selectedGateKeeper,
                      callback: function ($$v) {
                        _vm.selectedGateKeeper = $$v
                      },
                      expression: "selectedGateKeeper",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _vm.toSignProdAcc
                ? _c("v-select", {
                    attrs: {
                      options: _vm.productionAccountantUserList,
                      label: "fullName",
                      disabled: _vm.isDisabled,
                      placeholder: "Select a user",
                      reduce: (option) => option.id,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleChange(
                          $event,
                          _vm.DOCUMENT_VALIDATION_LEVEL.productionAccountant
                        )
                      },
                    },
                    model: {
                      value: _vm.selectedProductionAccountant,
                      callback: function ($$v) {
                        _vm.selectedProductionAccountant = $$v
                      },
                      expression: "selectedProductionAccountant",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _vm.toSignUPM
                ? _c("v-select", {
                    attrs: {
                      reduce: (option) => option.id,
                      disabled: _vm.isDisabled,
                      options: _vm.upmUserList,
                      label: "fullName",
                      placeholder: "Select a user",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleChange(
                          $event,
                          _vm.DOCUMENT_VALIDATION_LEVEL.upm
                        )
                      },
                    },
                    model: {
                      value: _vm.selectedUpm,
                      callback: function ($$v) {
                        _vm.selectedUpm = $$v
                      },
                      expression: "selectedUpm",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _vm.toSignLineProd
                ? _c("v-select", {
                    attrs: {
                      options: _vm.lineProducerUserList,
                      label: "fullName",
                      disabled: _vm.isDisabled,
                      reduce: (option) => option.id,
                      placeholder: "Select a user",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleChange(
                          $event,
                          _vm.DOCUMENT_VALIDATION_LEVEL.lineProducer
                        )
                      },
                    },
                    model: {
                      value: _vm.selectedLineProducer,
                      callback: function ($$v) {
                        _vm.selectedLineProducer = $$v
                      },
                      expression: "selectedLineProducer",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-center", attrs: { cols: "2" } },
            [
              _vm.toSignStudio
                ? _c("v-select", {
                    attrs: {
                      options: _vm.studioUserList,
                      label: "fullName",
                      disabled: _vm.isDisabled,
                      reduce: (option) => option.id,
                      placeholder: "Select a user",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleChange(
                          $event,
                          _vm.DOCUMENT_VALIDATION_LEVEL.studio
                        )
                      },
                    },
                    model: {
                      value: _vm.selectedStudio,
                      callback: function ($$v) {
                        _vm.selectedStudio = $$v
                      },
                      expression: "selectedStudio",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }